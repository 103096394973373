import React from "react"
import Page from "../components/page";
import { Link } from "gatsby";

const DisclaimerPage = (props) => {
  const pageTitle="Disclaimer";
  return (
    <Page location={props.location.href} pageTitle={pageTitle}>
    <h1>{pageTitle}</h1>
    <p>The ratings provided on this site are based on mathematical models and statistical analysis, intended for informational and entertainment purposes only. While we strive to ensure the accuracy and reliability of our ratings, they should not be interpreted as definitive assessments of team performance. Our methodology incorporates various factors, including game outcomes and strength of schedule, but like any statistical analysis, it cannot capture all the nuances of the sport.</p>
    <p>Please note that our ratings are proprietary and subject to change based on newly available data and potential adjustments to our calculation methods. The ratings are not affiliated with or endorsed by any college, conference, team, or associated organization.</p>
    <p>Users are cautioned against using the ratings as the sole basis for any betting or gambling activities. The website and its creators assume no responsibility for any financial losses or legal consequences resulting from the use of our ratings or content. All users are encouraged to use discretion and consider multiple sources of information when making decisions related to betting or team performance.</p>
    <p>By accessing and using the ratings on this site, users acknowledge and agree to the limitations and terms outlined in this disclaimer. For further inquiries or concerns about our ratings or methodology, please <Link to="/contactus">contact us</Link>.</p>
  </Page>
    );
};
export default DisclaimerPage;